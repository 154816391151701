import { Outlet } from 'react-router-dom'
import { VenueContextProvider } from './contexts/VenueContext'
import { App } from 'antd'

function AppRating() {
  return (
    <div className="App">
      <App>
        <VenueContextProvider>
          <Outlet />
        </VenueContextProvider>
      </App>
    </div>
  )
}

export default AppRating
