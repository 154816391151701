import {
  DEFAULT_BG_CONTAINER,
  DEFAULT_BG_LAYOUT,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_FONT_FAMILY,
  DEFAULT_PRIMARY_COLOR,
} from '../config/Skin'
import frFR from 'antd/locale/fr_FR'
import { useEffect } from 'react'
import { useVenues } from '../contexts/VenueContext'
import { Outlet, useParams } from 'react-router-dom'
import { ConfigProvider, Layout, Spin, Typography } from 'antd'

const { Text } = Typography

export const LayoutRating = () => {
  const { venueSlug } = useParams<{ venueSlug: string }>()

  const { venue, isLoading, fetchVenue } = useVenues()

  useEffect(() => {
    if (venueSlug === undefined) return
    fetchVenue(venueSlug)
  }, [venueSlug])

  if (isLoading === true) {
    return <Spin size="large" />
  }

  return (
    <ConfigProvider
      locale={frFR}
      theme={{
        token: {
          colorPrimary: venue?.style.primaryColor ?? DEFAULT_PRIMARY_COLOR,
          borderRadius: venue?.style.borderRadius ?? DEFAULT_BORDER_RADIUS,
          colorBgContainer: venue?.style.bgContainer ?? DEFAULT_BG_CONTAINER,
          colorBgLayout: venue?.style.bgLayout ?? DEFAULT_BG_LAYOUT,
          colorBorder: venue?.style.borderColor ?? DEFAULT_BORDER_COLOR,
          fontFamily: venue?.style.fontFamily ?? DEFAULT_FONT_FAMILY,
          colorText: venue?.style.primaryColor ?? DEFAULT_PRIMARY_COLOR,
          colorLink: venue?.style.primaryColor ?? DEFAULT_PRIMARY_COLOR,
        },
      }}
    >
      <>
        {venue === undefined && (
          <Layout
            style={{
              minHeight: '100vh',
              background:
                "url('https://scoresto.s3.rbx.perf.cloud.ovh.net/bg.webp')",
              backgroundSize: 'cover',
            }}
          >
            <div
              style={{
                maxWidth: 400,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            >
              <Text>Unable to find the venue</Text>
            </div>
          </Layout>
        )}
        {venue !== undefined && (
          <Layout
            style={{
              minHeight: '100vh',
              height: '100%',
              width: '100vw',
              backgroundImage: `url('${venue.style.bgImageUrl}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed',
              padding: 50,
            }}
          >
            <div
              style={{
                maxWidth: 400,
                margin: 'auto',
              }}
            >
              <Outlet />
            </div>
          </Layout>
        )}
      </>
    </ConfigProvider>
  )
}
