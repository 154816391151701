import { Typography } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const { Title } = Typography

const Showcase: React.FC = () => {
  useEffect(() => {
    window.location.href = 'https://supplement-bacon.com'
  }, [])

  return (
    <Title style={{ color: 'white', textAlign: 'center' }}>
      <Link to={'https://supplement-bacon.com'}>Supplément Bacon</Link>
      <br /> © {new Date().getFullYear()}
    </Title>
  )
}

export default Showcase
