import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { Venue } from '../models/Venue'
import axios from 'axios'
import { REVIEW_URL, VENUE_URL } from '../utils/url'
import WebFont from 'webfontloader'
import { ReviewCreate } from '../models/Review'
import { App } from 'antd'

type VenueContextProps = {
  fetchVenue: (slug: string) => void
  storeReview: (review: ReviewCreate) => Promise<void>
  venue: Venue | undefined
  isLoading: boolean
  isReviewLoading: boolean
}

const initVenueContextPropsState: VenueContextProps = {
  fetchVenue: () => {},
  storeReview: () => Promise.resolve(),
  venue: undefined,
  isLoading: false,
  isReviewLoading: false,
}

const VenueContext = createContext<VenueContextProps>(
  initVenueContextPropsState
)

const useVenues = () => useContext(VenueContext)

interface Props {}

const VenueContextProvider = ({ children }: PropsWithChildren<Props>) => {
  const { message } = App.useApp()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isReviewLoading, setIsReviewLoading] = useState<boolean>(false)
  const [venue, setVenue] = useState<Venue | undefined>(undefined)

  const fetchVenue = (slug: string): void => {
    setIsLoading(true)
    axios
      .get(`${VENUE_URL}/${slug}`)
      .then((res) => {
        const venue: Venue = res.data.data
        setVenue(venue)
        loadVenueFont(venue)
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const storeReview = (review: ReviewCreate): Promise<void> => {
    setIsReviewLoading(true)
    return axios
      .post(REVIEW_URL, review)
      .then(() => {})
      .catch((err) => {
        message.error("Erreur lors de l'enregistrement")
        throw err
      })
      .finally(() => {
        setIsReviewLoading(false)
      })
  }

  const loadVenueFont = (venue: Venue) => {
    WebFont.load({ google: { families: [venue.style.fontFamily] } })
  }

  return (
    <VenueContext.Provider
      value={{ fetchVenue, storeReview, venue, isLoading, isReviewLoading }}
    >
      {children}
    </VenueContext.Provider>
  )
}

export { VenueContextProvider, useVenues }
