import React, { useState } from 'react'
import { Venue } from '../models/Venue'
import { Rate, Space, Typography } from 'antd'

const { Title, Text } = Typography

interface OverallRateProps {
  venue: Venue
  handleRateChanged?: (newValue: number) => void
}

const OverallRate: React.FC<OverallRateProps> = ({
  venue,
  handleRateChanged,
}) => {
  const [rate, setRate] = useState<number>(0)

  return (
    <Space.Compact direction="vertical">
      <Title style={{ margin: 0 }}>{venue.texts.welcomeTitle}</Title>
      <Text style={{ marginBottom: 30 }}>{venue.texts.welcomeSubtitle}</Text>
      <Rate
        value={rate}
        onChange={(v) => {
          setRate(v)
          if (handleRateChanged !== undefined) handleRateChanged(v)
        }}
        style={{ fontSize: 35 }}
        character={undefined}
      />
    </Space.Compact>
  )
}

export { OverallRate }
