import { FC } from 'react'
import { Rating } from '../pages/Rating'
import AppRating from '../AppRating'
import Showcase from '../pages/Showcase'
import AppShowcase from '../AppShowcase'
import { Routes, Route } from 'react-router-dom'
import { LayoutRating } from '../layout/LayoutRating'
import { LayoutShowcase } from '../layout/LayoutShowcase'

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<AppShowcase />}>
        <Route element={<LayoutShowcase />}>
          <Route path="/" element={<Showcase />} />
        </Route>
      </Route>

      <Route element={<AppRating />}>
        <Route element={<LayoutRating />}>
          <Route path="/:venueSlug" element={<Rating />} />
        </Route>
      </Route>
    </Routes>
  )
}

export { AppRoutes }
