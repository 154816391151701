import React, { useState } from 'react'
import { Typography, Space } from 'antd'
import { BadReview } from '../components/BadReview'
import { useVenues } from '../contexts/VenueContext'
import { OverallRate } from '../components/OverallRate'

const { Text } = Typography

export const Rating: React.FC = () => {
  const { venue } = useVenues()

  const [step, setStep] = useState<'rate' | 'badReview'>('rate')
  const [reviewCreated, setReviewCreated] = useState<boolean>(false)

  const handleRateChange = (rate: number) => {
    if (rate > 3) {
      window.location.href = venue!.gmbUrl
      return
    }

    setStep('badReview')
  }

  const reset = () => {
    if (reviewCreated === true) return
    setStep('rate')
  }

  if (venue === undefined) {
    return <Text>Unable to find the venue</Text>
  }

  return (
    <Space direction="vertical" size={30} style={{ textAlign: 'center' }}>
      <img onClick={reset} style={{ width: 300 }} src={venue.logo} />
      {step == 'rate' && (
        <OverallRate venue={venue} handleRateChanged={handleRateChange} />
      )}
      {step == 'badReview' && (
        <BadReview
          venue={venue}
          onSubmitSuccess={() => setReviewCreated(true)}
        />
      )}
    </Space>
  )
}
