import { ConfigProvider } from 'antd'
import { Outlet } from 'react-router-dom'
import {
  DEFAULT_BG_CONTAINER,
  DEFAULT_BG_LAYOUT,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_FONT_FAMILY,
  DEFAULT_PRIMARY_COLOR,
} from './config/Skin'

function AppShowcase() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: DEFAULT_PRIMARY_COLOR,
            borderRadius: DEFAULT_BORDER_RADIUS,
            // Alias Token
            colorBgContainer: DEFAULT_BG_CONTAINER,
            colorBgLayout: DEFAULT_BG_LAYOUT,
            colorBorder: DEFAULT_BORDER_COLOR,
            fontFamily: DEFAULT_FONT_FAMILY,
          },
        }}
      >
        <Outlet />
      </ConfigProvider>
    </div>
  )
}

export default AppShowcase
