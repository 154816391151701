import React, { useState } from 'react'
import { Venue } from '../models/Venue'
import { useVenues } from '../contexts/VenueContext'
import {
  Form,
  Rate,
  Space,
  Typography,
  Input,
  Button,
  theme,
  Checkbox,
  Row,
  Col,
} from 'antd'
import { Link } from 'react-router-dom'

const { TextArea } = Input
const { Title, Text } = Typography

interface BadReviewProps {
  venue: Venue
  onSubmitSuccess?: () => void
}

const BadReview: React.FC<BadReviewProps> = ({ venue, onSubmitSuccess }) => {
  const [form] = Form.useForm()
  const { storeReview, isReviewLoading } = useVenues()

  const {
    token: { borderRadius },
  } = theme.useToken()

  const [step, setStep] = useState<'form' | 'success'>('form')
  const [wantToBeContacted, setWantToBeContacted] = useState<boolean>(false)

  return (
    <Space.Compact direction="vertical">
      {step === 'form' && (
        <>
          <Title style={{ margin: 0 }}>{venue.texts.badReviewTitle}</Title>
          <Text style={{ marginBottom: 30 }}>
            {venue.texts.badReviewSubtitle}
          </Text>
          <Form
            form={form}
            layout="vertical"
            disabled={isReviewLoading}
            initialValues={{ venue: venue.slug }}
            style={{ marginBottom: 30 }}
            onFinish={(values) => {
              storeReview(values).then(() => {
                setStep('success')
                if (onSubmitSuccess !== undefined) onSubmitSuccess()
              })
            }}
          >
            <Form.Item name={'venue'} hidden>
              <Input hidden />
            </Form.Item>

            {venue.items.map((item) => (
              <Form.Item
                key={item.id}
                label={item.name}
                name={['items', item.id.toString()]}
                rules={[{ required: true }]}
              >
                <Rate style={{ fontSize: 35 }} character={undefined} />
              </Form.Item>
            ))}
            <Form.Item label={'Notes'} name={'notes'}>
              <TextArea rows={10} maxLength={500} showCount />
            </Form.Item>
            {venue.gdprUrl &&
              venue.texts.gdprMention &&
              wantToBeContacted === false && (
                <Button
                  style={{
                    width: '100%',
                    borderRadius: borderRadius,
                    marginBottom: 20,
                  }}
                  onClick={() => setWantToBeContacted(true)}
                >
                  {'Je souhaite être recontacté(e)'}
                </Button>
              )}
            {venue.gdprUrl &&
              venue.texts.gdprMention &&
              wantToBeContacted === true && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item label={'Nom'} name={'lastName'}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={'Prénom'} name={'firstName'}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label={'Email'}
                    rules={[{ required: true, type: 'email' }]}
                    name={'email'}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label={'Téléphone'} name={'phone'}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={'gdpr_optin'}
                    valuePropName="checked"
                    style={{ textAlign: 'left' }}
                    rules={[{ required: true }]}
                  >
                    <Checkbox style={{ textAlign: 'left' }}>
                      <Link
                        to={venue.gdprUrl}
                        target="_blank"
                        style={{ fontWeight: 'bold' }}
                      >
                        {venue.texts.gdprMention}
                      </Link>
                    </Checkbox>
                  </Form.Item>
                </>
              )}
            <Button
              htmlType="submit"
              disabled={isReviewLoading}
              loading={isReviewLoading}
              style={{ width: '100%', borderRadius: borderRadius }}
            >
              {venue.texts.badReviewButtonLabel}
            </Button>
          </Form>
        </>
      )}
      {step === 'success' && (
        <Space direction="vertical">
          <Title style={{ margin: 0 }}>{venue.texts.successTitle}</Title>
          <Text style={{ marginBottom: 30 }}>
            {venue.texts.successSubtitle}
          </Text>
        </Space>
      )}
    </Space.Compact>
  )
}

export { BadReview }
