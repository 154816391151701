import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'

export const LayoutShowcase = () => {
  return (
    <Layout
      style={{
        height: '100vh',
        background: "url('/assets/bg.webp')",
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          maxWidth: 400,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%',
        }}
      >
        <Outlet />
      </div>
    </Layout>
  )
}
